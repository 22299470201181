import deployment from './deployment.json'

function hasProp<T extends object>(obj: T, key: PropertyKey): key is keyof T {
  return key in obj
}

export const apiConfig = () => {
  const config = {
    prd: {
      apiBaseUrl: 'https://api.centigrade.earth',
      webBaseUrl: 'https://listings.centigrade.earth',
      pinataGateway: 'https://purple-big-tapir-572.mypinata.cloud/ipfs',
      chain: 'testnet',
      ledgerExplorer: 'https://testnet.xrpl.org',
    },
    dev: {
      apiBaseUrl: 'https://devapi.centigrade.earth',
      webBaseUrl: 'https://devlistings.centigrade.earth',
      pinataGateway: 'https://purple-big-tapir-572.mypinata.cloud/ipfs',
      chain: 'testnet',
      ledgerExplorer: 'https://testnet.xrpl.org',
    },
    local: {
      apiBaseUrl: 'http://localhost:5000',
      webBaseUrl: 'http://localhost:3040',
      pinataGateway: 'https://purple-big-tapir-572.mypinata.cloud/ipfs',
      chain: 'testnet',
      ledgerExplorer: 'https://testnet.xrpl.org',
    },
  }

  const { env } = deployment
  if (!hasProp(config, env)) {
    throw new Error(`Unknown environment: ${env}`)
  }
  return config[env]
}
