import { useState, useCallback } from 'react'

export default function useDisclosure({ defaultIsOpen = false }) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])
  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])
  const onToggle = useCallback(() => {
    setIsOpen((isOpen) => !isOpen)
  }, [])
  const onOpenChange = useCallback((open: boolean) => {
    setIsOpen(open)
  }, [])

  return { isOpen, onOpen, onClose, onToggle, onOpenChange }
}
