import { useMemo } from 'react'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

const messages = {
  dataTypes: {
    name: 'Types of Data',
    tooltip: '',
  },
  historicalReferencePeriod: {
    name: 'Historical Reference Period',
    tooltip: '',
  },
  frequencyBaselineUpdates: {
    name: 'Frequency of Baseline Updates',
    tooltip: '',
  },
  currentFixedBaselinePeriod: {
    name: 'Current Fixed Baseline Period',
    tooltip: '',
  },
  standardization: {
    name: 'Standardization',
    tooltip: '',
  },
  transparency: {
    name: 'Transparency',
    tooltip: '',
  },
  notes: {
    name: 'Further Notes',
    tooltip: '',
  },
}

export function BaselineMetadata({ baselineMetadata }) {
  const attributes = useMemo(
    () =>
      (baselineMetadata ?? []).map(({ key, value }) => {
        if (key === 'currentFixedBaselinePeriod' && Array.isArray(value)) {
          value = value.join(' - ')
        }
        const attribute = { name: messages[key].name, value }
        const tooltip = messages[key].tooltip
        if (tooltip) {
          attribute.tooltip = tooltip
        }
        return attribute
      }),
    [baselineMetadata]
  )

  if (!attributes.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Baseline Key Variables Metadata
      </span>
      <AttributeList>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
