import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GrapheneRegistryService } from '../services/GrapheneRegistryService'
import CentigradeLogo from '../assets/img/centigrade_logo.svg'

export const Footer = () => {
  const [, setInfo] = useState({})

  useEffect(() => {
    GrapheneRegistryService.getInfo().then((res) => {
      setInfo(res.data)
    })
  }, [])

  return (
    <footer id="site-footer" className="flex flex-row justify-between p-8">
      <div className="w-full">
        <div>
          <CentigradeLogo className="h-[24px]" />
        </div>
        <hr className="mt-4 mb-2" />
        <div className="flex flex-col md:flex-row md:justify-between md:items-baseline">
          <div className="flex flex-row justify-between sm:justify-start text-md mb-4 md:mb-0 [&>:not(:last-child)]:mr-6">
            <a
              href="https://www.linkedin.com/company/centigradeearth/"
              target="_blank"
              rel="noreferrer"
              className="hover:underline hover:cursor-pointer"
            >
              LinkedIn
            </a>
            <a
              href="https://twitter.com/centigradeearth"
              target="_blank"
              rel="noreferrer"
              className="hover:underline hover:cursor-pointer"
            >
              Twitter
            </a>
          </div>
          <div className="flex flex-col items-center sm:items-end md:flex-row md:justify-end text-sm">
            <Link
              to="/terms"
              className="md:mr-2 hover:underline hover:cursor-pointer"
            >
              Terms and Privacy
            </Link>
            <p className="copyright">
              © 2023 Centigrade. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
