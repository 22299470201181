import { useMemo } from 'react'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

export function KeyAssumptions({ keyAssumptions }) {
  const attributes = useMemo(
    () =>
      (keyAssumptions ?? []).map(
        ({ assumptionName, assumptionValue, assumptionType }) => {
          const attribute = {
            name: assumptionName,
            value:
              assumptionType === 'Percent'
                ? `${assumptionValue}%`
                : assumptionValue,
          }
          return attribute
        }
      ),
    [keyAssumptions]
  )

  if (!attributes.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Key Assumptions
      </span>
      <AttributeList>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
