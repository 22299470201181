import { Person } from '../../../../components/icons'

export function ContactCard({ role, organization, contact }) {
  return (
    <div className="p-3 bg-centigrade-carbon/5 rounded">
      <div className="mb-2 text-centigrade-carbon/50 text-xs font-medium uppercase">
        {role}
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <div className="leading-tight font-medium">{organization.name}</div>
          {organization.address && (
            <div className="text-xs">{organization.address}</div>
          )}
        </div>
        {organization.description && (
          <p className="text-[0.625rem]">{organization.description}</p>
        )}
        {contact && (
          <div className="text-xs flex flex-col">
            <div className="font-medium flex flex-row gap-0.5">
              <Person className="inline text-[1.3333em] text-centigrade-carbon/50" />
              {contact.name}
            </div>
            {contact.description && <p>{contact.description}</p>}
            {contact.phone && (
              <a href={`tel:${contact.phone}`}>{contact.phone}</a>
            )}
            {contact.email && (
              <a
                className="text-centigrade-green hover:underline font-medium w-fit"
                href={`mailto:${contact.email}`}
              >
                {contact.email}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
