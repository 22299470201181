import { useMemo } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from '../../../components/core'

const knownEstimateNames = [
  'Forecast Issuance',
  'Baseline changes in carbon stocks in the project area',
  'Project carbon stock changes',
  'Assumed Leakage',
  'Risk Buffer Allocation',
  'Risk Buffer Release',
  'Paid Risk Buffer',
  'Deducted Credits',
]
const order = knownEstimateNames.reduce((map, name, index) => {
  map.set(name.toUpperCase(), index)
  return map
}, new Map())

export function BaselineEstimations({ baselineEstimates }) {
  const [columns, data] = useMemo(() => {
    const estimateYears = new Set()
    const estimatesByName = new Map()

    // Group estimates by name, with each record mapping estimateYear to estimateValue
    ;(baselineEstimates ?? []).forEach(
      ({ estimateName: name, estimateValue: value, estimateYear: year }) => {
        estimateYears.add(year)
        estimatesByName.set(name, {
          ...estimatesByName.get(name),
          [year]: value,
        })
      }
    )

    // Define the columns and data for the table. This is similar to what a library like
    // react-table would expect.
    const columns = [
      {
        id: 'name',
        accessor: 'name',
        header: 'Year',
        rowHeader: true,
        width: '20%',
        minWidth: '200px',
      },
      ...Array.from(estimateYears)
        .sort()
        .map((year) => ({
          id: year,
          accessor: year,
          header: year,
        })),
    ]

    const data = Array.from(estimatesByName).map(
      ([estimateName, estimates]) => ({
        name: estimateName,
        ...estimates,
      })
    )
    data.sort((rowA, rowB) => {
      const a = order.get(rowA.name.toUpperCase()) ?? Infinity
      const b = order.get(rowB.name.toUpperCase()) ?? Infinity
      return a === b ? 0 : a - b
    })

    return [columns, data]
  }, [baselineEstimates])

  if (!data.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Baseline Estimations
      </span>
      <div className="overflow-auto">
        <Table className="w-full">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th
                  key={column.id}
                  scope={column.rowHeader ? 'row' : 'col'}
                  style={{ width: column.width, minWidth: column.minWidth }}
                >
                  {column.header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, i) => (
              <Tr key={i}>
                {columns.map((column) => {
                  const Cell = column.rowHeader ? Th : Td
                  return (
                    <Cell
                      key={column.id}
                      style={{ width: column.width, minWidth: column.minWidth }}
                      {...(column.rowHeader ? { scope: 'row' } : undefined)}
                    >
                      {row[column.accessor]}
                    </Cell>
                  )
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  )
}
