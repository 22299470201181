import { useMemo } from 'react'
import { Attachment } from '../../../components/icons'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

const messages = {
  referenceRegion: {
    name: 'Reference Region',
    tooltip:
      'The spatial delimitation of the analytic domain from which information about rates, agents, drivers, and patterns of land-use and land-cover change (LU/LC-change) will be obtained, projected into the future and monitored',
  },
  historicalReferencePeriod: {
    name: 'Historical Reference Period',
    tooltip: '',
  },
  referenceRegionDocumentation: {
    name: 'Reference Region Documentation',
    tooltip: '',
  },
  leakageBelt: {
    name: 'Leakage Belt',
    tooltip:
      'The leakage belt is the land area(s) surrounding or adjacent to the project area in which baseline activities could be displaced due to the project activities implemented in the project area',
  },
  notes: {
    name: 'Further Notes',
    tooltip: 'Additional notes about the spatial boundaries of the project',
  },
}

export function SpatialBoundaries({ spatialBoundaries }) {
  const attributes = useMemo(
    () =>
      Object.entries(spatialBoundaries ?? {}).map(([key, value]) => {
        if (key === 'referenceRegionDocumentation') {
          value = (
            <a
              href={value}
              target="_blank"
              rel="noreferrer"
              className="text-centigrade-green hover:underline font-medium w-fit"
            >
              <Attachment className="inline text-[1.3333em] mr-1" />
              Link
            </a>
          )
        } else if (
          key === 'historicalReferencePeriod' &&
          Array.isArray(value)
        ) {
          value = value.join(' - ')
        } else if (key === 'notes' && Array.isArray(value)) {
          value = value.join('\n ')
        }
        const attribute = { name: messages[key].name, value }
        const tooltip = messages[key].tooltip
        if (tooltip) {
          attribute.tooltip = tooltip
        }
        return attribute
      }),
    [spatialBoundaries]
  )

  if (!attributes.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Spatial Boundaries
      </span>
      <AttributeList>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
