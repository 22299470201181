import { useMemo } from 'react'
import clsx from 'clsx'
import { MoreHoriz, TaskAlt } from '../../../../components/icons'

// Both ringSize and strokeWidth were chosen such that at 32px (ie. boxSize), the progress track/indicator
// would be the same size as the TaskAlt icon
const boxSize = 32
const ringSize = boxSize * (267 / 320)
const strokeWidth = boxSize * (27 / 320)
const cx = boxSize / 2
const cy = boxSize / 2
const r = (ringSize - strokeWidth) / 2
const ringLength = 2 * Math.PI * r

export function ProgressRing({ value, disabled }) {
  const indicator = useMemo(() => {
    return (
      <circle
        className="fill-none -rotate-90 origin-[50%_50%]"
        stroke="var(--progress-ring-indicator-color)"
        strokeDasharray="var(--progress-ring-length)"
        strokeDashoffset={`calc(var(--progress-ring-length) - (var(--progress-ring-length) * ${value}) / 100)`}
        strokeWidth="var(--progress-ring-stroke-width)"
        strokeLinecap="round"
        cx={cx}
        cy={cy}
        r={r}
      />
    )
  }, [value])

  const track = useMemo(() => {
    return (
      <circle
        className="fill-none"
        stroke="var(--progress-ring-track-color)"
        strokeWidth="var(--progress-ring-stroke-width)"
        cx={cx}
        cy={cy}
        r={r}
      />
    )
  }, [])

  const state = useMemo(() => {
    if (disabled) {
      return 'disabled'
    } else if (value === 0) {
      return 'pending'
    } else if (value === 100) {
      return 'complete'
    } else {
      return 'in-progress'
    }
  }, [value, disabled])

  const svgProps = {
    width: '1em',
    height: '1em',
    viewBox: '0 0 32 32',
  }

  const rootClassName = clsx(
    'relative flex justify-center items-center shrink-0 text-[32px]',
    '[--progress-ring-track-color:theme(colors.centigrade-carbon/10%)] [--progress-ring-indicator-color:theme(colors.centigrade-blue)]'
  )

  return (
    <div
      className={rootClassName}
      style={{
        '--progress-ring-size': `${ringSize}px`,
        '--progress-ring-length': `${ringLength}px`,
        '--progress-ring-stroke-width': `${strokeWidth}px`,
      }}
    >
      {state === 'disabled' && <svg {...svgProps}>{track}</svg>}
      {state === 'pending' && (
        <>
          <svg {...svgProps}>{track}</svg>
          <MoreHoriz className="absolute text-centigrade-carbon/50 text-[0.5em]" />
        </>
      )}
      {state === 'in-progress' && (
        <svg {...svgProps}>
          {track}
          {indicator}
          <text
            className="text-[8px]"
            textAnchor="middle"
            dominantBaseline="central"
            x={cx}
            y={cy}
          >
            {value}%
          </text>
        </svg>
      )}
      {state === 'complete' && (
        <TaskAlt className="text-[--progress-ring-indicator-color]" />
      )}
    </div>
  )
}
