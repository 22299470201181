import clsx from 'clsx'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

export interface TooltipProps
  extends Pick<TooltipPrimitive.TooltipProps, 'open' | 'onOpenChange'>,
    TooltipPrimitive.TooltipContentProps {
  content: string
  children: React.ReactNode
}

export function Tooltip({
  content,
  open,
  onOpenChange,
  children,
  className,
  style,
  ...props
}: TooltipProps) {
  const contentClassName = clsx(
    'bg-centigrade-green text-white p-2 max-w-sm rounded-sm text-sm shadow-md',
    'origin-[--radix-tooltip-content-transform-origin] animate-tooltip',
    className
  )

  return (
    <TooltipPrimitive.Provider delayDuration={0}>
      <TooltipPrimitive.Root open={open} onOpenChange={onOpenChange}>
        <TooltipPrimitive.Trigger asChild>
          <span>{children}</span>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          side="top"
          align="center"
          collisionPadding={16}
          className={contentClassName}
          style={{ ...style, '--scale-start': 0.85 } as React.CSSProperties}
          {...props}
        >
          {content}
          <TooltipPrimitive.Arrow
            width={12}
            height={6}
            className="fill-centigrade-green"
          />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
