import deployment from './deployment.json';

export const apiConfig = () => {
    const config = {
        prd: {
            apiBaseUrl: 'https://api.centigrade.earth',
            webBaseUrl: 'https://listings.centigrade.earth',
            pinataGateway: 'https://purple-big-tapir-572.mypinata.cloud/ipfs',
            chain: 'testnet',
            ledgerExplorer: 'https://livenet.xrpl.org',
        },
        dev: {
            apiBaseUrl: 'https://devapi.centigrade.earth',
            webBaseUrl: 'https://devlistings.centigrade.earth',
            pinataGateway: 'https://purple-big-tapir-572.mypinata.cloud/ipfs',
            chain: 'testnet',
            ledgerExplorer: 'https://testnet.xrpl.org',
        },
        local: {
            apiBaseUrl: 'http://localhost:5000',
            webBaseUrl: 'http://localhost:3040',
            pinataGateway: 'https://purple-big-tapir-572.mypinata.cloud/ipfs',
            chain: 'testnet',
            ledgerExplorer: 'https://testnet.xrpl.org',
        },
    };
    return config[deployment.env];
};

