import './styles.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastProvider } from './components/core'
import { Home } from './pages/Home'
import { Terms } from './pages/Terms'
import ProjectsRoutes  from './pages/Projects/ProjectsRoutes'
import TokensRoutes  from './pages/Tokens/TokensRoutes'
import ReservesRoutes  from './pages/Reserves/ReservesRoutes'

const App: React.FC = () => {
  return (
    <ToastProvider>
      <BrowserRouter>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/projects/*" element={<ProjectsRoutes />} />
          <Route path="/tokens/*" element={<TokensRoutes />} />
          <Route path="/reserves/*" element={<ReservesRoutes />} />
        </Routes>
      </BrowserRouter>
    </ToastProvider>
  );
};

export default App;
