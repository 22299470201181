import clsx from 'clsx'
import * as ToastPrimitive from '@radix-ui/react-toast'
import { Close } from '../icons'

export interface ToastProps extends ToastPrimitive.ToastProps {
  title?: string
  content: string
}
export interface ToastProviderProps {
  children?: React.ReactNode
}

export function Toast({ title, content, ...rest }: ToastProps) {
  return (
    <ToastPrimitive.Root
      {...rest}
      className={clsx(
        'flex flex-row gap-3 items-center max-w-xs md:max-w-md bg-centigrade-purple text-white p-4 rounded shadow-lg',
        'data-[state=open]:animate-[slideIn_150ms_cubic-bezier(0.16,1,0.3,1)] data-[state=closed]:animate-[fadeOut_140ms_ease-out]'
      )}
    >
      <div className="flex flex-col gap-1">
        {title && (
          <ToastPrimitive.Title className="font-semibold">
            {title}
          </ToastPrimitive.Title>
        )}
        <ToastPrimitive.Description className="text-sm">
          {content}
        </ToastPrimitive.Description>
      </div>
      <ToastPrimitive.Close
        aria-label="Close"
        className="self-start -m-0.5 p-1 hover:bg-white/10 active:bg-white/20 rounded-sm"
      >
        <span aria-hidden>
          <Close />
        </span>
      </ToastPrimitive.Close>
    </ToastPrimitive.Root>
  )
}

export function ToastProvider({ children }: ToastProviderProps) {
  return (
    <ToastPrimitive.Provider>
      {children}
      <ToastPrimitive.Viewport className="fixed right-4 bottom-4" />
    </ToastPrimitive.Provider>
  )
}
