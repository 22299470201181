import clsx from 'clsx'
import { useEffect, useState, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import merge from 'deepmerge'
import { GrapheneRegistryService } from '../../../services/GrapheneRegistryService'
import { Page } from '../../../components/Page'
import { Spinner } from '../../../components/core/Spinner'
import { ArrowBack } from '../../../components/icons'
import { ProjectHeader } from './ProjectHeader'
import { TierProgress } from './TierProgress'
import { ProjectDetails } from './ProjectDetails'
import { ProjectEntities } from './ProjectEntities'
import { Safeguards } from './Safeguards'
import { BaselineEstimations } from './BaselineEstimations'
import { BaselineMetadata } from './BaselineMetadata'
import { Durability } from './Durability'
import { KeyAssumptions } from './KeyAssumptions'
import { SystemBoundaries } from './SystemBoundaries'
import { SpatialBoundaries } from './SpatialBoundaries'
import { Methodology } from './Methodology'

// TODO: This is for demo purposes only. Remove once we have real data
import demoProjectImageUrl from '../../../assets/img/demo_project.png'
import tier0 from '../../../../data/ccah_project_tier_0.json'
import tier1 from '../../../../data/ccah_project_tier_1.json'

function getProjectImageUrl(iwaProject) {
  return iwaProject.name ===
    'REDD+ Project in the Alto Huayabamba Conservation Concession (CCAH)'
    ? demoProjectImageUrl
    : undefined
}

function isEmpty(data) {
  if (Array.isArray(data)) {
    return data.length === 0
  } else if (typeof data === 'object') {
    return Object.keys(data).length === 0
  } else {
    return data == null
  }
}

const combineMerge = (target, source, options) => {
  const destination = target.slice()
  source.forEach((item, index) => {
    if (typeof destination[index] === 'undefined') {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
    } else if (options.isMergeableObject(item)) {
      destination[index] = merge(target[index], item, options)
    } else if (target.indexOf(item) === -1) {
      destination.push(item)
    }
  })
  return destination
}

const demoProjectTier0 = tier0
const demoProjectTier1 = merge(tier0, tier1, { arrayMerge: combineMerge })

export function Project() {
  const { id } = useParams()

  const [, setProject] = useState()
  const [iwaProject, setIwaProject] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      // TODO: This is for demo purposes only. Remove once we have real data
      if (id === 'demo0' || id === 'demo1') {
        setIwaProject(id === 'demo0' ? demoProjectTier0 : demoProjectTier1)
        setLoading(false)
        return
      }

      const { data: project } = await GrapheneRegistryService.getProject(id)
      setProject(project)
      //setIwaProject(JSON.parse(project.json_blob))
      setIwaProject(project) //should now be the schema
      setLoading(false)
    }
    fetchData()
  }, [id])

  const {
    details,
    safeguards,
    entities,
    methodology,
    durability,
    systemBoundaries,
    spatialBoundaries,
    keyAssumptions,
    baselineMetadata,
    baselineEstimates,
  } = useMemo(() => {
    if (iwaProject) {
      const {
        externalId,
        parisAgreementCompliance,
        landOwnedLegally,
        participants,
        country, 
        projectScale,
        modularBenefitProjects: [
          {
            ecologicalBenefitScope,
            ecologicalBenefitType,
            classificationCategory,
            classificationMethod,
            safeguards,
            durability,
            systemBoundaries,
            spatialBoundaries,
            keyAssumptions,
            baselineMetadata,
            baselineEstimates,
            benefitClaims: [
              {
                methodology,
                methodologyAuthors,
                version,
                expertReview,
                publicConsultation,
                commentPeriod,
                publicCommentsLink,
              },
            ],
          },
        ],
      } = iwaProject

      const details = {
        externalId,
        country,
        projectScale,
        ecologicalBenefitScope,
        ecologicalBenefitType,
        classificationCategory,
        classificationMethod,
        parisAgreementCompliance,
        landOwnedLegally,
      }

      const protocol = {
        methodology,
        methodologyAuthors,
        version,
        expertReview,
        publicConsultation,
        commentPeriod,
        publicCommentsLink,
      }

      const data = {
        // Tier 0 - should be available for all projects (required for listing)
        details,
        safeguards,
        entities: participants,
        // Tier 1 - only available as data is collected
        methodology: Object.values(protocol).every((v) => v === undefined)
          ? undefined
          : protocol,
        durability,
        systemBoundaries,
        spatialBoundaries,
        keyAssumptions,
        baselineMetadata,
        baselineEstimates,
      }

      // Treat empty values as null so that we can more easily check if data is available
      for (const key in data) {
        data[key] = isEmpty(data[key]) ? null : data[key]
      }

      return data
    }

    return {}
  }, [iwaProject])

  // TODO: This is for demo purposes only. Used to fake tier progress and show the Methodology section
  // Methodology data is currently available in tier0, but should only be in tier1
  const currentTier = durability == null ? 0 : 1

  return (
    <Page>
      <Link
        to="/projects"
        className="text-centigrade-green hover:underline font-medium flex flex-row items-center gap-2 mb-7 md:mb-14"
      >
        <ArrowBack className="w-6 h-6" />
        Back to Projects
      </Link>
      {loading ? (
        <div className="py-10 mb-14 flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex flex-row flex-wrap items-start gap-16">
            <div className="flex-[5]">
              <ProjectHeader
                title={iwaProject.name}
                url={iwaProject.domain}
                description={iwaProject.description}
                imageUrl={getProjectImageUrl(iwaProject)}
              />
            </div>
            <div className="flex-1">
              <TierProgress
                progress={[100, currentTier === 0 ? 0 : 42, 0, 0]}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-10 mt-16">
            <div className={clsx('col-span-2', { hidden: !baselineEstimates })}>
              <BaselineEstimations baselineEstimates={baselineEstimates} />
            </div>
            <div className={clsx('col-span-2', { hidden: !baselineMetadata })}>
              <BaselineMetadata baselineMetadata={baselineMetadata} />
            </div>
            <div className="flex flex-col gap-8">
              <Durability durability={durability} />
              <KeyAssumptions keyAssumptions={keyAssumptions} />
              <ProjectDetails details={details} />
              <Safeguards safeguards={safeguards} />
            </div>
            <div className="flex flex-col gap-8">
              <SystemBoundaries systemBoundaries={systemBoundaries} />
              <SpatialBoundaries spatialBoundaries={spatialBoundaries} />
              {currentTier === 1 && <Methodology methodology={methodology} />}
              <ProjectEntities entities={entities} />
            </div>
          </div>
        </div>
      )}
    </Page>
  )
}
