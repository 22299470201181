import { useMemo } from 'react'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

const messages = {
  nameEnum: {
    ABOVE_GROUND_TREES: 'Carbon Pools - Above-ground (Trees)',
    ABOVE_GROUND_NON_TREE: 'Carbon Pools - Above-ground (Non-tree)',
    BELOW_GROUND: 'Carbon Pools - Below-ground',
    DEAD_WOOD: 'Carbon Pools - Dead Wood',
    HARVESTED_WOOD_PRODUCTS: 'Carbon Pools - Harvested Wood Products',
    LITTER: 'Carbon Pools - Litter',
    SOIL_ORGANIC_CARBON: 'Carbon Pools - Soil Organic Carbon',
    BIOMASS_BURNING_CO2: 'Carbon Pools - Biomass Burning',
    LIVESTOCK_EMISSIONS_CO2: 'Carbon Pools - Livestock Emissions',
    USES_OF_FERTILIZERS_CO2: 'Carbon Pools - Uses of Fertilizers',
  },
  valueEnum: {
    INCLUDED: 'Included',
    EXCLUDED: 'Excluded',
  },
}

export function SystemBoundaries({ systemBoundaries }) {
  const attributes = useMemo(
    () =>
      (systemBoundaries ?? []).map(({ name, value }) => {
        const attribute = {
          name: messages.nameEnum[name],
          value: messages.valueEnum[value],
        }
        return attribute
      }),
    [systemBoundaries]
  )

  if (!attributes.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        System Boundaries
      </span>
      <AttributeList>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
