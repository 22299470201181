import { useEffect, useState } from "react";

import yaml from 'json-to-pretty-yaml';



/**
 * simple component to display of hashed data
 * @param {*} param0 
 * @returns 
 */
export const YamlViewer = ({hashedInfo}) => {

    const [prettyYaml, setPrettyYaml] = useState(yaml.stringify(hashedInfo));

    return (
        <>
            {hashedInfo && 
             <div className="flex flex-col w-full">       
                <div className="bg-centigrade-creme-200 text-centigrade-carbon-300 p-2 w-full rounded">
                    <pre className="code w-full text-sm overflow-x-auto">
                        {prettyYaml}
                    </pre>
                </div>
            </div>}
        </>
    );
  };


  