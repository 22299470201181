import { Page } from '../components/Page'

export const Terms = () => {
  return (
    <Page>
      <article className="single-page prose max-w-full p-2">
        <header>
          <h1 className="post-title">Terms and Privacy</h1>
        </header>
        <div className="post-content">
          <p>Effective Date: December 26th, 2023</p>
          <h4>
            <b>Introduction</b>
          </h4>
          <p>
            Centigrade, Inc. and its subsidiaries and affiliated companies
            (“Centigrade”, “we”, “our”, “us”) understand and respect our users’
            need for privacy. This Centigrade Privacy Policy (“Privacy Policy”)
            explains how Centigrade collects, uses, and shares information we
            collect about you when you use our websites (including our company
            website
            <a href="http://www.centigrade.earth">www.centigrade.earth</a>, the
            “Site”), and when you interact with our services.
          </p>
          <p>
            This Policy applies when you interact with us through our Services.
            It also applies anywhere it is linked. It does not apply to
            third-party websites, mobile applications, or services that may link
            to the Services or be linked to from the Services. Please review the
            privacy policies on those websites and applications directly to
            understand their privacy practices.
          </p>
          <p>
            We may change this Policy from time to time. If we do, we will
            notify you by posting the updated version.
          </p>
          <h4>
            <b>How We Collect Your Information</b>
          </h4>
          <h4>
            <i>Information We Collect From Our Websites and Events</i>:
          </h4>
          <p>
            Information You Provide to Us: We collect information you provide
            directly to us when you browse our Site, create an online account,
            post messages to our forums or wikis, provide feedback through
            surveys, participate in any interactive features, contests,
            promotions, sweepstakes, activities, or events. The types of
            information we may collect include: your name, email address,
            username, password, location and any other information you choose to
            provide.
          </p>
          <p>
            Information We Collect Automatically Through the Use of Cookies and
            Other Tracking Technology: When you visit the Company’s website, we
            obtain certain information by automated means, such as cookies, web
            beacons, web server logs, and other technologies. A “cookie” is a
            text file that websites send to a visitor’s computer or other
            Internet-connected device to identify the visitor’s browser or to
            store information or settings in the browser. A “web beacon”, also
            known as an Internet tag, pixel tag or clear GIF, links web pages to
            web servers and their cookies and may be used to transmit
            information collected through cookies back to a web server.
          </p>
          <p>
            We may use these automated technologies to collect information about
            your equipment, browsing actions, and usage patterns. The
            information we obtain in this manner may include: your device IP
            address, identifiers associated with your devices, types of devices
            connected to our services, web browser characteristics, device
            characteristics, language preferences, referring/exit pages,
            clickstream data and dates and times of visits to our Site.
          </p>
          <p>
            The information we collect through cookies and similar technologies
            helps us (1) remember your information so you will not have to
            re-enter it; (2) understand how you use and interact with our
            website; (3) measure the usability of our website and the
            effectiveness of our communications; and (4) otherwise manage and
            enhance our website, and (5) help ensure it is working properly.
          </p>
          <p>
            Your browser may tell you how to be notified when you receive
            certain types of cookies or how to restrict or disable certain types
            of cookies. Please note, however, that without cookies you may not
            be able to use all the features of our website.
          </p>
          <h4>
            <b>How We Use Your Information</b>
          </h4>
          <p>
            We may use any of the information we collect for the following
            purposes.
          </p>
          <h4>
            <i>Centigrade Websites and Events:</i>
          </h4>
          <p>
            Centigrade collects this data for various purposes, including to:
          </p>
          <ul>
            <li>
              Respond to your comments, questions and requests, and provide
              customer service;
            </li>
            <li>
              Monitor and analyze trends, usage, and activities in order to
              operate and improve our Site;
            </li>
            <li>
              Manage your online account(s) and send you technical notices,
              updates, security alerts and support and administrative messages;
            </li>
            <li>
              Carry out any other purpose for which the information was
              collected.
            </li>
          </ul>
          <h4>
            <b>How We Share Your Information</b>
          </h4>
          <h4>
            <i>Centigrade Websites and Events:</i>
          </h4>
          <p>We may share your personal information as follows:</p>
          <ul>
            <li>
              With vendors, consultants and other service providers who need
              access to such information to carry out work on our behalf;
            </li>
            <li>
              In response to a request for information if we believe disclosure
              is in accordance with any applicable law, regulation or legal
              process, or as otherwise required by any applicable law, rule, or
              regulation;
            </li>
            <li>
              If we believe your actions are inconsistent with our user
              agreements or policies, or to protect the rights, property, and
              safety of us or any third party;
            </li>
            <li>
              In connection with, or during negotiations of, any merger, sale of
              company assets, financing or acquisition of all or a portion of
              our business to another company; and
            </li>
            <li>With your consent or at your direction.</li>
          </ul>
          <p>
            We may also share aggregated or de-identified information, which
            cannot reasonably be used to identify you.
          </p>
          <p>
            Please see the Appendix C to this Privacy Policy for a list of
            sub-processors that Centigrade uses to provide its various services.
          </p>
          <h4>
            <b>Security</b>
          </h4>
          <p>
            We use a combination of physical, technical, and administrative
            safeguards to protect the information we collect through the
            Services. While we use these precautions to safeguard your
            information, we cannot guarantee the security of the networks,
            systems, servers, devices, and databases we operate or that are
            operated on our behalf.
          </p>
          <h4>
            <b>Location of Processing</b>
          </h4>
          <p>
            Centigrade’s headquarters are in the United States, and it has
            offices around the world. In providing its services, Centigrade may
            process your personal information on servers outside the country
            where you live, including to countries that do not have the same
            data protection laws as your country. Although the data protection
            laws of various countries may differ from those in your own country,
            we take appropriate steps to ensure that your personal information
            is processed as described in this Privacy Policy and under the law.
          </p>
          <p>
            If you are located in the European Economic Area, please see the
            “Transfer of Personal Information to Other Countries” section in
            Appendix B for more information.
          </p>
          <h4>
            <b>How We Protect Your Information</b>
          </h4>
          <p>
            We are committed to protecting your information. We maintain
            appropriate administrative, technical, and physical safeguards
            designed to protect the personal information you provide against
            accidental, unlawful, or unauthorized destruction, loss, alteration,
            access, disclosure, or use.
          </p>
          <h4>
            <b>How Long We Retain Your Information</b>
          </h4>
          <p>
            We will retain your personal information on file for as long as
            necessary for our legitimate business purposes. Please note that in
            certain cases, legal or regulatory obligations require us to retain
            specific records for a longer period of time.
          </p>
          <h4>
            <b>Children’s Information</b>
          </h4>
          <p>
            Our Services are not directed to children under the age of 18. If
            you learn that a child under the age of 18 has provided us with
            personal information without consent, please contact us.
          </p>
          <h4>
            <b>Your Rights</b>
          </h4>
          <p>
            You have the right to request a copy of your information, to object
            to our use of your personal information (including for marketing
            purposes), where applicable, to correct your personal information,
            to request the deletion or restriction of your personal information,
            or to request your personal information in a structured, electronic
            format.
          </p>
          <p>
            Please note that we may retain certain information as required by
            law or for legitimate business purposes. If you would like to
            exercise any of these rights, please contact
            privacy@centigrade.earth.
          </p>
          <p>
            Depending on where you are located, you may have certain rights in
            connection with your personal information that we obtain. These
            rights vary depending on your jurisdiction. To learn more about your
            rights, see:
          </p>
          <ul>
            <li>
              Appendix A for CCPA rights if you are a California resident.
            </li>
            <li>
              Appendix B for GDPR rights if you are located in the EEA or United
              Kingdom.
            </li>
          </ul>
          <h4>
            <b>Changes To This Privacy Policy</b>
          </h4>
          <p>
            We periodically review and update this Privacy Policy to describe
            new services or changes to our practices. You can determine when
            this Privacy Policy was last revised by referring to the “Updated”
            date at the top of this document. We encourage you to review the
            Privacy Policy whenever you interact with us to stay informed about
            our privacy practices and the ways that you can help protect your
            privacy.
          </p>
          <h4>
            <b>Appeals</b>
          </h4>
          <p>
            Residents of Colorado, Connecticut, and Virginia have the right to
            appeal a denial of their request by contacting us as described in
            the notice of denial.
          </p>
          <h4>
            <b>Third-Party Services, Applications, and Websites</b>
          </h4>
          <p>
            Certain third-party services, websites, or applications you use, or
            navigate to from our Services may have separate user terms and
            privacy policies that are independent of this Policy. This includes,
            for example, websites owned and operated by our customers or
            partners. We are not responsible for the privacy practices of these
            third-party services or applications. We recommend carefully
            reviewing the user terms and privacy statement of each third-party
            service, website, and/or application prior to use.
          </p>
          <h4>
            <b>Contacting Us</b>
          </h4>
          <p>
            To submit questions regarding this Privacy Policy, you can contact
            Centigrade by emailing us at privacy@centigrade.earth or at our
            mailing address at:
          </p>
          <p>
            Centigrade, Inc.
            <br />
            Attention: General Counsel
            <br />
            600 Battery St.
            <br />
            San Francisco, CA 94111
            <br />
            U.S.A.
          </p>
          <h4>
            <b>Appendix A: California Consumer Privacy Act Addendum</b>
          </h4>
          <p>
            Additional provisions applicable to processing personal information
            of California residents.
          </p>
          <h4>
            <i>Scope and Applicability</i>
          </h4>
          <p>
            This Appendix A to the Privacy Policy (“Appendix A”) applies to
            California residents and outlines individuals’ rights and choices
            with respect to the processing of your personal information under
            the California Consumer Privacy Act (“CCPA”). This Appendix A
            controls to the extent it conflicts with any provision in the main
            body of the Privacy Policy.
          </p>
          <h4>
            <i>Data Collection, Use and Sharing</i>
          </h4>
          <p>
            The CCPA requires that we inform individuals whether we collect any
            categories of personal information as classified under the CCPA, and
            whether we share this information with selected recipients for
            specific purposes.
          </p>
          <h4>
            <i>Categories of Information</i>
          </h4>
          <p>
            We collect the following categories of information as classified
            under CCPA:
          </p>
          <ul>
            <li>
              <b>Personal identifiers</b> – We collect name, IP address, email
              address, and username.
            </li>
            <li>
              <b>Internet or other network activity information</b> – We collect
              IP address, device information, clickstream data, and dates and
              times of visits to our Site.
            </li>
            <li>
              <b>Geolocation</b> – We collect geolocation information directly
              from Site visitors and, on a less granular level, automatically on
              the Centigrade platform through the use of cookies and related
              technologies.
            </li>
          </ul>
          <p>
            Please see the “How We Collect Your Information” section in our
            Privacy Policy to see the full description of the information that
            we collect.
          </p>
          <p>
            Publicly Available Information: Personal information does not
            include publicly available information. For purposes of this
            paragraph, “publicly available” means information that is lawfully
            made available from federal, state, or local government records.
          </p>
          <h4>
            <i>Sharing Information</i>
          </h4>
          <p>
            We share the information collected with third-party service
            providers such as suppliers, vendors, business partners (e.g.,
            advertising partners, consultants) to operate our business.
            <b>We do not sell personal information.</b>
          </p>
          <h4>
            <i>Using Information</i>
          </h4>
          <p>We collect and use personal information to:</p>
          <ul>
            <li>Respond to your requests for information</li>
            <li>Conduct internal research and development;</li>
            <li>
              Improve the quality and safety of our products and services; AND
            </li>
            <li>Detect security incidents and prevent fraud.</li>
          </ul>
          <p>
            Please see the “How We Use Your Information” section in the Privacy
            Policy for a full description of how we use personal information.
          </p>
          <h4>
            <i>Your CCPA Rights</i>
          </h4>
          <p>
            The CCPA grants California consumers certain rights in connection
            with the personal information we collect, as described below.
          </p>
          <ul>
            <li>
              <b>Right to Know:</b> You have the right to know the categories
              and specific pieces of your personal information we have collected
              in the previous 12 months.
            </li>
            <li>
              <b>Right to Deletion:</b> You have the right to request that we
              delete any of your personal information we have collected.
            </li>
            <li>
              <b>Right to Request Information:</b> You have the right to request
              information about the collection, sale, and disclosure of your
              personal information from the previous 12 months.
            </li>
            <li>
              <b>Right to Opt-out of the Sale of Information:</b> You have the
              right to opt-out of the sale of personal information we have
              collected about you.
            </li>
            <li>
              <b>Right to Non-Discrimination:</b> You have the right to not
              receive discriminatory treatment for exercising any of your CCPA
              rights. We do not treat individuals differently for exercising any
              of the rights described above.
            </li>
          </ul>
          <h4>
            <i>Exercising Your Rights</i>
          </h4>
          <p>
            If you wish to exercise any of the above rights, see the “Contact
            Us” section in this Appendix A. You may also authorize an individual
            to submit a verifiable consumer request relating to your personal
            information.
          </p>
          <p>
            We verify your request using your email address. Government
            identification may be required. We cannot respond to your request if
            we cannot verify your identity and/or authority to make the request
            on behalf of another and confirm the personal information relates to
            you.
          </p>
          <p>
            If you wish to use an authorized agent to submit a request to
            opt-out on your behalf, you must provide the authorized agent
            written permission signed by you, the consumer. We may deny a
            request from an authorized agent if the agent cannot provide
            Centigrade with your signed permission demonstrating that the agent
            is authorized to act on your behalf.
          </p>
          <p>
            We fulfil requests within 45 days of receiving your request. Please
            note that your request may be limited in certain cases, for example
            if complying with your request would conflict with:
          </p>
          <ul>
            <li>Federal, state, or local law;</li>
            <li>Regulatory inquiries;</li>
            <li>Subpoenas; or</li>
            <li>Exercising or defending legal claims.</li>
          </ul>
          <h4>
            <i>Contact Us</i>
          </h4>
          <p>
            To submit questions regarding this Privacy Policy, you can contact
            Centigrade by emailing us at privacy@centigrade.earth or at our
            mailing address at:
          </p>
          <p>
            Centigrade, Inc.
            <br />
            Attention: General Counsel
            <br />
            600 Battery St.
            <br />
            San Francisco, CA 94111
            <br />
            U.S.A.
          </p>
          <h4>
            <b>Appendix B: General Data Protection Regulation Addendum</b>
          </h4>
          <p>
            Additional provisions applicable to the processing personal
            information of individuals based in the European Economic Area
            (“EEA”) and the United Kingdom (“UK”).
          </p>
          <h4>
            <i>Scope and Applicability</i>
          </h4>
          <p>
            This Appendix B to the Privacy Policy (“Appendix B”) applies to
            individuals based in the EEA and UK and outlines their rights and
            choices regarding the processing of their personal information under
            the General Data Protection Regulation or related regulations
            (collectively, the “GDPR”). Capitalized terms not defined in this
            Appendix B are defined in the Privacy Policy above
          </p>
          <h4>
            <i>Data Collection, Use, and Sharing</i>
          </h4>
          <p>
            We collect and process personal information about you only where we
            have a legal basis for doing so under applicable data protection
            laws. Our legal bases include processing personal information under:
          </p>
          <ul>
            <li>
              <b>Your consent:</b> Where appropriate or legally required, we
              collect and use information about you subject to your consent. For
              example, we collect your email address when you sign up for emails
              and updates on the Centigrade Services.
            </li>
            <li>
              <b>Legitimate interests: </b>We collect and use information about
              you for our legitimate interests to deliver content, optimize your
              experience, and market the Centigrade Services. For example, we
              collect your IP address and device information when you use the
              Centigrade Services to prevent malicious activity.
            </li>
            <li>
              <b>Compliance with laws:</b> We may also collect and use
              information about you:
              <ul>
                <li>
                  As required by law, such as to comply with a subpoena or
                  similar legal process;
                </li>
                <li>
                  When we believe in good faith that disclosure is necessary to
                  protect our rights or property, protect your health and safety
                  or the health and safety of others, investigate fraud, or
                  respond to a government request; or
                </li>
                <li>
                  If we participate in a merger, acquisition, or sale of all or
                  a portion of its assets.
                </li>
              </ul>
            </li>
          </ul>
          <h4>
            <i>Transfer of Personal Information to Other Countries</i>
          </h4>
          <p>
            We transfer your personal information to countries outside the
            United Kingdom and the European Economic Area (“EEA”), including,
            but not limited to the United States, where Centigrade Corporate
            Headquarters is located, and where our IT systems (including email)
            are located. When required, we may transfer and store personal
            information to locations in or outside the European Economic Area
            (“EEA”).
          </p>
          <p>
            If we transfer your personal information out of the European
            Economic Area, United Kingdom, or Switzerland and are required to
            apply additional safeguards to your personal information under
            European data protection legislation, we will do so. Such safeguards
            may include applying the European Commission-approved standard
            contractual data protection clauses or other appropriate legal
            mechanisms.
          </p>
          <h4>
            <i>Data Subject Rights</i>
          </h4>
          <p>
            You have certain rights related to your personal information when
            you access the Centigrade website. Some of these rights only apply
            in certain circumstances, as set out below. We also describe how to
            exercise these rights in the “Exercising your Rights” section of
            this Appendix B.
          </p>
          <p>The GDPR provides data subjects with the following rights:</p>
          <ul>
            <li>
              <b>Right of Access:</b> Data subjects have the right to request
              access and receive certain information about how we use their
              personal information and who we share it with.
            </li>
            <li>
              <b>Right to Rectification: </b>Data subjects have the right to
              request correction of personal information we hold about them
              where it is inaccurate or incomplete.
            </li>
            <li>
              <b>Right to Data Portability:</b> Data subjects have the right to
              request a copy of data we hold about them in a structured,
              machine-readable format, and to ask us to share this information
              with another entity.
            </li>
            <li>
              <b>Right to Erasure:</b> Data subjects have the right to request
              deletion of the personal information we hold about them:
              <ul>
                <li>
                  Where one believes that it is no longer necessary for us to
                  hold their personal information;
                </li>
                <li>
                  Where we are processing personal information based on
                  legitimate interests and a data subject objects to such
                  processing, and we cannot demonstrate an overriding legitimate
                  ground for the processing;
                </li>
                <li>
                  Where a data subject has provided their personal information
                  to us with consent, and they wish to withdraw their consent
                  and there is no other ground under which we can process their
                  personal information; or
                </li>
                <li>
                  Where a data subject believes their personal information is
                  being unlawfully processed by us.
                </li>
              </ul>
            </li>
            <li>
              <b>Right to Restriction of Processing: </b>Data subjects have the
              right to ask us to restrict (stop any active) processing of their
              personal information:
              <ul>
                <li>
                  Where they believe their personal information is inaccurate
                  and while we verify accuracy;
                </li>
                <li>
                  Where we want to erase their personal information as the
                  processing is unlawful, but they want us to continue to store
                  it;
                </li>
                <li>
                  Where we no longer need their personal information for our
                  processing, but they require us to retain the data for the
                  establishment, exercise, or defense of legal claims; or
                </li>
                <li>
                  Where they have objected to us processing their personal
                  information based on our legitimate interests and we are
                  considering their objection.
                </li>
              </ul>
            </li>
            <li>
              <b>Right to Object:</b> Data subjects can object to our processing
              of their personal information based on our legitimate interests.
              We will no longer process their personal information unless we can
              demonstrate an overriding legitimate purpose.
            </li>
            <li>
              <b>
                Objection to Marketing, Automated Decision Making, and
                Profiling:{' '}
              </b>
              Data subjects have the right to object to our processing of
              personal information for marketing communications, automated
              decision making and profiling. We will stop processing the data
              for that purpose.
            </li>
            <li>
              <b>Withdrawal of Consent:</b> Where a data subject has provided
              consent for us to process their personal information, they can
              withdraw their consent by emailing privacy@centigrade.earth.
            </li>
          </ul>
          <p>
            Please note that before we respond to requests for information, we
            will require that identity verification, or the identity of any data
            subject for whom one is requesting information. Our verification
            methods may include requesting that the data subject log into their
            Centigrade account, confirm their contact information or email
            address, and/or provide documents for identity verification.
          </p>
          <p>
            Centigrade commits to resolve complaints about our collection or use
            of your personal information. EU, UK, and Swiss individuals with
            inquiries or complaints should first contact Centigrade using the
            contact information provided below.
          </p>
          <h4>
            <i>Exercising Your Rights</i>
          </h4>
          <p>
            To exercise any of the rights above, please contact us as noted in
            the “Contact Us” section in this Appendix B. If you are a Customer
            end user, contact the applicable Customer acting as the data
            controller directly to fulfill any requests.
          </p>
          <p>
            We will fulfill your request within 30 days of receipt. Please note
            that the above rights may be limited in the following situations:
          </p>
          <ul>
            <li>
              Where fulfilling your request would adversely affect other
              individuals, company trade secrets or intellectual property;
            </li>
            <li>Where there are overriding public interest reasons; or</li>
            <li>
              Where we are required by law to retain your personal information.
            </li>
          </ul>
          <p>
            If you have unresolved concerns, we encourage you to come to us in
            the first instance, but you are entitled to address any grievance
            directly to the relevant supervisory authority. If you are a
            Customer end user, we encourage you to reach out to the relevant
            Customer first to address any complaints.
          </p>
          <h4>
            <i>Contacting Us</i>
          </h4>
          <p>
            To submit questions regarding this Privacy Policy, you can contact
            Centigrade by emailing us at privacy@centigrade.earth or at our
            mailing address at:
          </p>
          <p>
            Centigrade, Inc.
            <br />
            Attention: General Counsel
            <br />
            600 Battery St.
            <br />
            San Francisco, CA 94111
            <br />
            U.S.A.
          </p>
        </div>
      </article>
    </Page>
  )
}
