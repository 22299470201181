import { Link } from 'react-router-dom'
import CentigradeLogo from '../assets/img/centigrade_logo.svg'

export const Header = () => {
  return (
    <header className="px-8 py-6 border-b-2 border-gray-200 text-black">
      <Link to="/">
        <CentigradeLogo className="h-[24px]" />
      </Link>
    </header>
  )
}
