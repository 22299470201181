import clsx from 'clsx'

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {}
export interface TheadProps
  extends React.HTMLAttributes<HTMLTableSectionElement> {}
export interface TbodyProps
  extends React.HTMLAttributes<HTMLTableSectionElement> {}
export interface TrProps extends React.HTMLAttributes<HTMLTableRowElement> {}
export interface ThProps extends React.HTMLAttributes<HTMLTableCellElement> {}
export interface TdProps extends React.HTMLAttributes<HTMLTableCellElement> {}

export function Table({ children, className, ...rest }: TableProps) {
  return (
    <table
      className={clsx(
        '[&>thead>tr:first-of-type>:first-child]:rounded-tl-lg [&>thead>tr:first-of-type>:last-child]:rounded-tr-lg',
        '[&>tbody:first-child>tr:first-of-type>:first-child]:rounded-tl-lg [&>tbody:first-child>tr:first-of-type>:last-child]:rounded-tr-lg',
        '[&>tbody>tr:last-of-type>:first-child]:rounded-bl-lg [&>tbody>tr:last-of-type>:last-child]:rounded-br-lg',
        'text-center',
        className
      )}
      {...rest}
    >
      {children}
    </table>
  )
}

export function Thead({ children, className, ...rest }: TheadProps) {
  return (
    <thead
      className={clsx(
        'text-centigrade-purple [&>tr>*]:bg-centigrade-purple/25  [&>tr>*]:border-centigrade-purple/10',
        className
      )}
      {...rest}
    >
      {children}
    </thead>
  )
}

export function Tbody({ children, className, ...rest }: TbodyProps) {
  return (
    <tbody
      className={clsx(
        '[&>tr:nth-child(odd)>*]:bg-centigrade-carbon/[.02] [&>tr:nth-child(even)>*]:bg-centigrade-carbon/[.05] [&>tr>*]:border-centigrade-carbon/10',
        className
      )}
      {...rest}
    >
      {children}
    </tbody>
  )
}

export function Tr({ children, className, ...rest }: TrProps) {
  return (
    <tr
      className={clsx(
        'font-medium [&>:not(:last-child)]:border-r-2',
        className
      )}
      {...rest}
    >
      {children}
    </tr>
  )
}

export function Th({ children, className, ...rest }: ThProps) {
  return (
    <th
      className={clsx(
        'p-2 leading-tight font-medium [&[scope=row]]:uppercase [&[scope=row]]:text-xs',
        className
      )}
      {...rest}
    >
      {children}
    </th>
  )
}

export function Td({ children, className, ...rest }: TdProps) {
  return (
    <td className={clsx('p-2 leading-tight font-medium', className)} {...rest}>
      {children}
    </td>
  )
}
