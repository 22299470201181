import { Children, useState, useEffect } from 'react'
import { Button } from '../../../../components/core'

export function AttributeList({ children, maxItems }) {
  const attributes = Children.toArray(children)

  // Here, null means that the user has not clicked the Show More button.
  // Once they do, collapsed will be set to false and any change to attributes.length
  // or maxItems will have no effect.
  const [collapsed, setCollapsed] = useState(null)

  useEffect(() => {
    if (typeof maxItems === 'number') {
      setCollapsed((collapsed) =>
        collapsed === null ? attributes.length > maxItems : collapsed
      )
    }
  }, [attributes.length, maxItems])

  return (
    <div className="relative">
      <ul className="flex flex-col gap-[calc(theme(spacing.1)/2)] rounded-lg overflow-hidden">
        {collapsed ? attributes.slice(0, maxItems) : attributes}
      </ul>
      {collapsed && (
        <div className="absolute bottom-0 flex flex-row items-end justify-center h-32 w-full bg-gradient-to-b from-white/0 to-white/100">
          <div className="w-fit bg-white">
            <Button
              onClick={() => {
                setCollapsed(false)
              }}
            >
              Show More
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
