import { useMemo } from 'react'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

const messages = {
  externalId: {
    name: 'Identifier',
    tooltip:
      'An identifier that is issued and independent of the “name” of the project. This is used to reference the project and link it to its claims, verification, and issued credits issued',
  },
  country: {
    name: 'Country',
    tooltip: 'The host country for the project',
  },
  projectScale: {
    name: 'Project Scale',
    tooltip:
      'Estimated tonnes of CO2e to be sequestered or avoided over the life of the project',
  },
  ecologicalBenefitScope: {
    name: 'Project Scope',
    tooltip:
      'The sector type that the emission reduction or sequestration activities is in',
  },
  ecologicalBenefitType: {
    name: 'Project Type',
    tooltip: 'The emission reduction or sequestration pathway of the project',
  },
  classificationCategory: {
    name: 'Classification Category',
    tooltip: 'Ecological benefit category for the project',
  },
  classificationMethod: {
    name: 'Classification Method',
    tooltip: 'Category of the mitigation activity',
  },
  parisAgreementCompliance: {
    name: 'Paris Agreement Compliance',
    tooltip: 'Whether the project is in compliance with the Paris Agreement',
  },
  landOwnedLegally: {
    name: 'Land Owned Legally',
    tooltip: 'Whether the land is owned legally',
  },
}

export function ProjectDetails({ details }) {
  const attributes = useMemo(
    () =>
      Object.entries(details ?? {}).map(([key, value]) => {
        const attribute = { name: messages[key].name, value }
        const tooltip = messages[key].tooltip
        if (tooltip) {
          attribute.tooltip = tooltip
        }
        return attribute
      }),
    [details]
  )

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Project Details
      </span>
      <AttributeList>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
