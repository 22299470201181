import { cloneElement } from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import clsx from 'clsx'
import { Person } from '../icons'

function getInitials(name: string) {
  return name
    .split(/\s+/)
    .slice(0, 2)
    .map((str) => str.charAt(0).toUpperCase())
    .join('')
}

export interface AvatarProps extends AvatarPrimitive.AvatarProps {
  size: 'md' | 'lg' | 'xl'
  name?: string
  src?: string
  icon?: React.ReactElement<React.SVGProps<SVGSVGElement>>
}

export function Avatar({
  size = 'md',
  name,
  src,
  icon,
  className,
  ...rest
}: AvatarProps) {
  const rootClassName = clsx(
    'flex flex-row items-center justify-center shrink-0 bg-gray-200',
    'overflow-hidden rounded-full',
    {
      'w-12 h-12 text-[calc(theme(spacing.12)/2.5)]': size === 'md',
      'w-16 h-16 text-[calc(theme(spacing.16)/2.5)]': size === 'lg',
      'w-20 h-20 text-[calc(theme(spacing.20)/2.5)]': size === 'xl',
    },
    className
  )

  const iconClassName = 'w-4/5 h-4/5 text-centigrade-carbon/50'
  const fallbackIcon = icon ? (
    cloneElement(icon, {
      role: 'img',
      className: clsx(iconClassName, icon.props.className),
    })
  ) : (
    <Person role="img" className={iconClassName} />
  )

  return (
    <AvatarPrimitive.Root className={rootClassName} {...rest}>
      <AvatarPrimitive.Image src={src} alt={name} />
      <AvatarPrimitive.Fallback className="flex flex-row items-center justify-center w-full h-full">
        {name ? getInitials(name) : fallbackIcon}
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  )
}
