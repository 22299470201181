import { Button, type ButtonProps } from './Button'

export interface IconButtonProps
  extends Omit<ButtonProps, 'leftIcon' | 'rightIcon'> {
  icon: React.ReactNode
  'aria-label': string
}

export function IconButton({
  icon,
  'aria-label': ariaLabel,
  ...rest
}: IconButtonProps) {
  return (
    <Button aria-label={ariaLabel} {...rest}>
      <span className="text-[1.3333em] text-centigrade-carbon/50">{icon}</span>
    </Button>
  )
}
