import { useMemo } from 'react'
import clsx from 'clsx'
import clamp from '../../../utils/clamp'
import useDisclosure from '../../../utils/useDisclosure'
import { Button, Toast } from '../../../components/core'
import { ProgressRing } from './components/ProgressRing'

const messages = {
  tier0: {
    title: 'Safeguards',
    description: 'This project meets basic provenance data requirements.',
  },
  tier1: {
    title: 'Forward Credits',
    description:
      'This project does not meet data criteria to facilitate forward credit issuance.',
  },
  tier2: {
    title: 'Verified Credits',
    description:
      'This project does not meet data criteria to facilitate results-based credit issuance.',
  },
  tier3: {
    title: 'Differentiating Factors',
    description: 'This project does not meet data criteria for certification.',
  },
}

export function TierProgress({ progress }) {
  const toast = useDisclosure({ defaultIsOpen: false })

  const tiers = useMemo(() => {
    // Ensure value is between 0 and 100
    const tierProgress = progress.map((value) => clamp(value, 0, 100))

    return Array.from(new Array(4), (_, index) => {
      const { title, description } = messages[`tier${index}`]
      return {
        title,
        description,
        progress: tierProgress[index],
        disabled: index > 0 && tierProgress[index - 1] < 100,
      }
    })
  }, [progress])

  return (
    <div className="flex flex-col max-w-fit">
      <ul className="flex flex-col gap-4 p-6 bg-centigrade-creme">
        {tiers.map(({ title, description, progress, disabled }, index) => {
          return (
            <li
              key={index}
              className="flex flex-row items-center gap-3 min-w-[22ch]"
            >
              <ProgressRing value={progress} disabled={disabled} />
              <div
                className={clsx('flex flex-col', {
                  'text-centigrade-carbon/50': disabled,
                })}
              >
                <span>{title}</span>
                <span className="text-xs">{description}</span>
              </div>
            </li>
          )
        })}
      </ul>
      <div className="bg-centigrade-amber p-6 flex flex-col gap-4">
        <span className="text-xs">
          Be notified when this project reaches the next tier.
        </span>
        <Button className="w-full" size="md" onClick={toast.onOpen}>
          Subscribe
        </Button>
      </div>
      <Toast
        open={toast.isOpen}
        onOpenChange={toast.onOpenChange}
        type="foreground"
        content="You are now subscribed to updates from this project."
      />
    </div>
  )
}
