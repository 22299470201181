import { useMediaQuery } from 'react-responsive'
import { Avatar, Button, IconButton } from '../../../components/core'
import {
  CompareArrows,
  Eco,
  HeartPlus,
  MoreHoriz,
} from '../../../components/icons'

export function ProjectHeader({ title, description, url, imageUrl }) {
  const isMediumScreen = useMediaQuery({ minWidth: '768px' })

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-[min-content_auto] auto-rows-auto gap-x-4 gap-y-2 items-center">
        <Avatar
          size={isMediumScreen ? 'xl' : 'lg'}
          src={imageUrl}
          icon={<Eco />}
          className="row-span-2"
        />
        <h1 className="mt-0 sm:min-w-[16ch] leading-[1.2] line-clamp-3 text-3xl md:text-4xl">
          {title}
        </h1>
        <div className="flex flex-row gap-2">
          <Button size="sm" leftIcon={<HeartPlus />}>
            Add to Favorites
          </Button>
          <Button size="sm" leftIcon={<CompareArrows />}>
            Compare
          </Button>
          <IconButton
            size="sm"
            icon={<MoreHoriz />}
            aria-label="More actions"
          />
        </div>
      </div>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="text-centigrade-green hover:underline font-medium w-fit"
      >
        {url}
      </a>
      <p className="text-sm">{description}</p>
    </div>
  )
}
