{
  "modularBenefitProjects": [
    {
      "durability": {
        "creditingPeriod": ["October 30th, 2013", "December 31st, 2047"],
        "commitmentPeriod": null,
        "reversalRisk": "Material",
        "storageType": "N/A",
        "durabilityInsuranceType": "Buffer Pool",
        "insurancePolicyOwner": "Issuing Registry"
      },
      "keyAssumptions": [
        {
          "assumptionName": "Anticipated deforestation rate of project area in baseline scenario",
          "assumptionValue": 0.035573863,
          "assumptionType": "Number"
        },
        {
          "assumptionName": "Anticipated effectiveness in preventing unplanned deforestation after project intervention",
          "assumptionValue": 90,
          "assumptionType": "Percent"
        },
        {
          "assumptionName": "Anticipated discount rate for leakage",
          "assumptionValue": 0,
          "assumptionType": "Percent"
        },
        {
          "assumptionName": "Anticipated discount rate for leakage",
          "assumptionValue": 0,
          "assumptionType": "Percent"
        },
        {
          "assumptionName": "Further Notes",
          "assumptionValue": "The Project does not include a leakage belt as there is not a forested area around the Project Area where leakage may occur. The community inside the CCAH agrees with the implementation of the proposed REDD Project activities. Towards the western edge, the Project is bordered by jalca. Because the jalca areas are natural grasslands, there is no forest cover in the area for people to harvest; therefore, there is no way for leakages to occur.Towards the eastern side, the CCAH is bounded by the Biocorredor Martin Sagrado REDD Project that is registered in VCS and is formed by three concessions for conservation: Martin Sagrado, El Breo, and Montecristo. Therefore, according to the methodology, it is necessary to exclude any areas that are part of other VCS AFOLU projects from the leakage belt to avoid the double counting of emissions. The Project is bounded bythe Los Chilchos Private Conservation Area in the north and the Rio Abiseo National Park in the south. In both the northern and southern zones, there are no roads to get into the concession or the Natural Park, which shields the Project from any leakage in those areas.It will be necessary to move into forest areas inside the CCAH that are unexplored due to the steep terrains (see Figure 14).",
          "assumptionType": "String"
        }
      ],
      "systemBoundaries": [
        {
          "name": "ABOVE_GROUND_TREES",
          "value": "INCLUDED"
        },
        {
          "name": "ABOVE_GROUND_NON_TREE",
          "value": "INCLUDED"
        },
        {
          "name": "BELOW_GROUND",
          "value": "INCLUDED"
        },
        {
          "name": "DEAD_WOOD",
          "value": "EXCLUDED"
        },
        {
          "name": "HARVESTED_WOOD_PRODUCTS",
          "value": "INCLUDED"
        },
        {
          "name": "LITTER",
          "value": "EXCLUDED"
        },
        {
          "name": "SOIL_ORGANIC_CARBON",
          "value": "EXCLUDED"
        },
        {
          "name": "BIOMASS_BURNING_CO2",
          "value": "EXCLUDED"
        },
        {
          "name": "LIVESTOCK_EMISSIONS_CO2",
          "value": "EXCLUDED"
        },
        {
          "name": "USES_OF_FERTILIZERS_CO2",
          "value": "EXCLUDED"
        }
      ],
      "baselineEstimates": [
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2013,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2013,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2013,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2013,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2013,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2013,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2013,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2013,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2013,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2014,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2014,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2014,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2014,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2014,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2014,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2014,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2014,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2014,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2015,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2015,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2015,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2015,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2015,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2015,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2015,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2015,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2015,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2016,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2016,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2016,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2016,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2016,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2016,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2016,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2016,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2016,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2017,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2017,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2017,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2017,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2017,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2017,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2017,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2017,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2017,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2018,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2018,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2018,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2018,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2018,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2018,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2018,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2018,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2018,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2019,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2019,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2019,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2019,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2019,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2019,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2019,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2019,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2019,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2020,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2020,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2020,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2020,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2020,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2020,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2020,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2020,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2020,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2021,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2021,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2021,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2021,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2021,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2021,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2021,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2021,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2021,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2022,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2022,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2022,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2022,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2022,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2022,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2022,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2022,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2022,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2023,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2023,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2023,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2023,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2023,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2023,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2023,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2023,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2023,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2024,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2024,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2024,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2024,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2024,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2024,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2024,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2024,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2024,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2025,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2025,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2025,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2025,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2025,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2025,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2025,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2025,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2025,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2026,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2026,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2026,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2026,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2026,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2026,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2026,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2026,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2026,
          "estimateValue": 66527
        },
        {
          "estimateName": "Forecast Issuance",
          "estimateYear": 2027,
          "estimateValue": 66527
        },
        {
          "estimateName": "Baseline changes in carbon stocks in the project area",
          "estimateYear": 2027,
          "estimateValue": 66527
        },
        {
          "estimateName": "Project carbon stock changes",
          "estimateYear": 2027,
          "estimateValue": 66527
        },
        {
          "estimateName": "Assumed Leakage",
          "estimateYear": 2027,
          "estimateValue": 0
        },
        {
          "estimateName": "Risk Buffer Allocation",
          "estimateYear": 2027,
          "estimateValue": 66527
        },
        {
          "estimateName": "Risk Buffer Release",
          "estimateYear": 2027,
          "estimateValue": 0
        },
        {
          "estimateName": "Paid Risk Buffer",
          "estimateYear": 2027,
          "estimateValue": 66527
        },
        {
          "estimateName": "Deducted Credits",
          "estimateYear": 2027,
          "estimateValue": 0
        },
        {
          "estimateName": "Anticipated deforestation rate in baseline scenario",
          "estimateYear": 2027,
          "estimateValue": 66527
        }
      ],
      "baselineMetadata": [
        {
          "key": "dataTypes",
          "value": "Inference - Historical Records"
        },
        {
          "key": "historicalReferencePeriod",
          "value": "2000 - 2012"
        },
        {
          "key": "frequencyBaselineUpdates",
          "value": "10 years"
        },
        {
          "key": "currentFixedBaselinePeriod",
          "value": "October 30th, 2013 - December 31st, 2047"
        },
        {
          "key": "standardization",
          "value": "Third-party models mandated by standards"
        },
        {
          "key": "transparency",
          "value": "Proprietary"
        },
        {
          "key": "notes",
          "value": "Baseline emissions were quantified by Peru's Forest Reference Emission Level."
        }
      ],
      "spatialBoundaries": {
        "referenceRegion": "Raw file not available to public",
        "historicalReferencePeriod": ["1999", "2013"],
        "referenceRegionDocumentation": "chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://redd.unfccc.int/media/frel__submission_peru_modified.pdf",
        "leakageBelt": "Raw file not available to public",
        "notes": [
          "1. The Project does not include a leakage belt as there is not a forested area around the Project Area where leakage may occur. The no inclusion of leakage is considered as a methodological deviation due to the methodology requires monitoring of leakage. This methodological deviation is based on the following factors: The community inside the CCAH agrees with the implementation of the proposed REDD Project activities. User families settled in the sectors of Nuevo Bolívar and El Progreso have been making use of the forest for many years before the presence of the Project Proponent. Additionally, despite the precarious living conditions, they decided to remain in the Project Area carrying out their activities in a traditional manner, but they also agree with the implementation of activities linked to the protection of forested areas.",
          "2. Towards the western edge, the Project is bordered by jalca. Because the jalca areas are natural grasslands, there is no forest cover in the area for people to harvest; therefore, there is no way for leakages to occur.",
          "3. Towards the eastern side, the CCAH is bounded by the Biocorredor Martin Sagrado REDD Project that is registered in VCS and is formed by three concessions for conservation: Martin Sagrado, El Breo, and Montecristo. Therefore, according to the methodology, it is necessary to exclude any areas that are part of other VCS AFOLU projects from the leakage belt to avoid the double counting of emissions."
        ]
      },
      "carbonAccountingQuantites": {},
      "carbonQuantityAssumptions": {},
      "claimSource": {},
      "baselineProjectAreaEvidenceData": {},
      "projectCarbonStockChangesEvidenceData": {}
    }
  ]
}
