import clsx from 'clsx'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'md'
  leftIcon?: React.ReactElement<React.SVGProps<SVGSVGElement>>
  rightIcon?: React.ReactElement<React.SVGProps<SVGSVGElement>>
}

export function Button({
  className,
  size = 'md',
  leftIcon,
  rightIcon,
  children,
  ...rest
}: ButtonProps) {
  const buttonClassName = clsx(
    'flex flex-row items-center justify-center gap-1 leading-tight',
    'border-2 border-centigrade-carbon/20 rounded',
    'text-centigrade-carbon hover:bg-centigrade-carbon/[.07] active:bg-centigrade-carbon/[.14]',
    {
      'px-3 py-2 text-xs': size === 'sm',
      'px-4 py-3': size === 'md',
    },
    className
  )

  const iconClassName = 'text-[1.3333em] text-centigrade-carbon/50'

  return (
    <button className={buttonClassName} {...rest}>
      {leftIcon && <span className={iconClassName}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={iconClassName}>{rightIcon}</span>}
    </button>
  )
}
