import { useMemo } from 'react'
import { Attachment } from '../../../components/icons'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

const messages = {
  methodology: {
    name: 'Protocol',
    tooltip: 'Methodology or protocol used for credit generation',
  },
  methodologyAuthors: {
    name: 'Author',
    tooltip: '',
  },
  version: {
    name: 'Version',
    tooltip: 'The version of methodology in use for this project',
  },
  expertReview: {
    name: 'Expert Review',
    tooltip:
      'Whether the methodology or protocol used has undergone independent expert review(s)',
  },
  publicConsultation: {
    name: 'Public Consultation',
    tooltip:
      'Whether the methodology or protocol used has undergone pubic consultation(s)',
  },
  commentPeriod: {
    name: 'Public Comment Period',
    tooltip: '',
  },
  publicCommentsLink: {
    name: 'Public Comments',
    tooltip: '',
  },
}

export function Methodology({ methodology }) {
  const attributes = useMemo(
    () =>
      Object.entries(methodology ?? {}).map(([key, value]) => {
        if (key === 'publicCommentsLink') {
          value = (
            <a
              href={value}
              target="_blank"
              rel="noreferrer"
              className="text-centigrade-green hover:underline font-medium w-fit"
            >
              <Attachment className="inline text-[1.3333em] mr-1" />
              Link
            </a>
          )
        }
        const attribute = { name: messages[key].name, value }
        const tooltip = messages[key].tooltip
        if (tooltip) {
          attribute.tooltip = tooltip
        }
        return attribute
      }),
    [methodology]
  )

  if (!attributes.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Methodology
      </span>
      <AttributeList>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
