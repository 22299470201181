{
  "name": "REDD+ Project in the Alto Huayabamba Conservation Concession (CCAH)",
  "description": "The Alto Huayabamba Conservation Concession (CCAH) is a territory granted under a Conservation Concession contract to the Asociación Amazónicos por la Amazonía (AMPA) on August 15th, 2007 for a renewable period of 40 years, to be used for biodiversity protection, environmental education and scientific research. Sustainable productive activities, land management planning, environmental education and the strengthening of governance are the measures implemented by the project to reduce emissions associated with deforestation and forest degradation (REDD+) of the CCAH, contributing to the fight against the negative impacts of climate change, while generating benefits for natural ecosystems and the communities that inhabit them.",
  "domain": "https://www.southpole.com/projects/alto-huayabamba-conservation",
  "owners": ["Asociación Amazónicos por la Amazonía (AMPA)"],
  "addresses": [],
  "projectInfo": {
    "externalId": "e5b57dc4-b0ee-47f7-9899-1d4ed96499dd",
    "country": "Peru",
    "projectScale": "LARGE",
    "region": "SOUTH_AMERICA",
    "mediaLinks": [],
    "registryId": "1882"
  },
  "modularBenefitProjects": [
    {
      "sponsors": ["Interconexión Eletrica S.A. E.S.P. ISA"],
      "developers": [
        {
          "role": "developer",
          "name": "South Pole Carbon Asset Management S.A.S.",
          "address": "Carrera 46 #7-59 Medellin, Colombia",
          "contact": "Jhoanata Bolivar",
          "title": "Head of AFOLU Projects",
          "email": "j.bolivar@southpole.com",
          "phone": "+57 4 3524428",
          "description": "South Pole elaborates and oversees the development of appropriate project design and monitoring techniques in lines with guidelines of the VCS and CCBS"
        }
      ],
      "geographicLocation": "https://registry.verra.org/mymodule/ProjectDoc/Project_ViewFile.asp?FileID=31435&IDKEY=n98klasmf8jflkasf8098afnasfkj98f0a9sfsakjflsakjf8df43348865",
      "classificationCategory": "CARBON_AVOIDANCE",
      "classificationMethod": "NATURE",
      "projectScope": "FORESTRY_AND_LAND_USE",
      "projectType": "REDD_PLUS",
      "benefitClaims": [
        {
          "methodology": "VM0015",
          "methodologyAuthors": "Amazonas Sustainable Foundation, BioCarbon Fund, The World Bank",
          "version": "1.1",
          "expertReview": "Unknown",
          "publicConsultation": "Yes",
          "commentPeriod": "14 July 2009 until 13 August 2009",
          "publicCommentsLink": "https://verra.org/methodologies/vm0015-methodology-for-avoided-unplanned-deforestation-v1-1/",
          "uncertaintyDiscounting": "TBD",
          "forecastIssuance": 6734,
          "baselineChange": 8907,
          "projectStockChange": 891,
          "assumedLeakage": 0,
          "riskBufferAllocation": 1282,
          "riskBufferRelease": 0,
          "paidRiskBuffer": 1282,
          "deductedCredits": 0
        }
      ],
      "safeguards": [
        {
          "standardName": "Compliance with laws ",
          "standardValueType": "String",
          "standardValue": "Yes",
          "standardLink": null
        },
        {
          "standardName": "Are you compliant with the applicable national laws of the project country?",
          "standardValueType": "Single select dropdown",
          "standardValue": "Yes, fully compliant",
          "standardLink": null
        },
        {
          "standardName": "Please provide nationally-compliant code of conduct documents or other evidence supporting your claim if present",
          "standardValueType": "Upload",
          "standardValue": "Yes",
          "standardLink": null
        },
        {
          "standardName": "Anti Money Laundering",
          "standardValueType": "String",
          "standardValue": "Yes",
          "standardLink": null
        },
        {
          "standardName": "Are you compliant with relevant AML programs?",
          "standardValueType": "Single select dropdown",
          "standardValue": "Yes, fully compliant",
          "standardLink": null
        },
        {
          "standardName": "Please upload relevant documentation if present",
          "standardValueType": "Upload",
          "standardValue": "Yes",
          "standardLink": null
        },
        {
          "standardName": "Child Labor",
          "standardValueType": "String",
          "standardValue": "Yes",
          "standardLink": null
        },
        {
          "standardName": "Are you planning on employing persons under the age of 15? ",
          "standardValueType": "Single select dropdown",
          "standardValue": "No",
          "standardLink": null
        },
        {
          "standardName": "Labor Rights, Slavery and Protection of Workers",
          "standardValueType": "String",
          "standardValue": "Yes",
          "standardLink": null
        }
      ]
    }
  ],
  "participants": [
    {
      "role": "Proponent",
      "organizationName": "Test Organization",
      "address": "Mza. N. Lote 1. Urb. Vista Alegre. Moyobamba. San Martín, Perú",
      "contact": "Amaya Farley",
      "contactDescription": null,
      "email": null,
      "phone": null,
      "organizationDescription": null
    },
    {
      "role": "Project/Benefit Owner",
      "organizationName": "Asociacion Amazonicos por la Amazonia (AMPA)",
      "address": null,
      "contact": null,
      "contactDescription": null,
      "email": null,
      "phone": null,
      "organizationDescription": null
    },
    {
      "role": "Sponsor",
      "organizationName": "Interconexión Eletrica S.A. E.S.P. ISA",
      "address": null,
      "contact": null,
      "contactDescription": null,
      "email": null,
      "phone": null,
      "organizationDescription": null
    },
    {
      "role": "Political Advisory",
      "organizationName": "Red de Conservación Voluntaria y Comunal de San Martín - RedCVCSM",
      "address": "Mza. N. Lote 1. Urb. Vista Alegre. Moyobamba. San Martín, Perú",
      "contact": "Armando Rodríguez Tynan",
      "contactDescription": "Head of AFOLU Projects",
      "email": "redconservacion.sanmartin@gmail.com",
      "phone": "+51 94 275 44 66",
      "organizationDescription": "The RedCVCSM is a political advocacy space for the sustainability of conservation initiatives in San Martín; it also provides support in monitoring deforestation."
    },
    {
      "role": "Biodiversity Advisory",
      "organizationName": "Panthera Colombia",
      "address": "Calle 3 Oeste 3A-18",
      "contact": "Matthew Hyde",
      "contactDescription": "Territorial Planning and Zoning Coordinator",
      "email": "mhyde@panthera.org",
      "phone": "+51 94 275 44 66",
      "organizationDescription": ""
    },
    {
      "role": "Developer",
      "organizationName": "South Pole Carbon Asset Management S.A.S.",
      "address": "Carrera 46 #7-59 Medellin, Colombia",
      "contact": "Jhoanata Bolivar",
      "contactDescription": "Head of AFOLU Projects",
      "email": "j.bolivar@southpole.com",
      "phone": "+57 4 3524428",
      "organizationDescription": "South Pole elaborates and oversees the development of appropriate project design and monitoring techniques in lines with guidelines of the VCS and CCBS"
    }
  ],
  "parisAgreementCompliance": true,
  "landOwnedLegally": true
}
