import { useMemo } from 'react'
import { Attachment } from '../../../components/icons'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

export function Safeguards({ safeguards }) {
  const attributes = useMemo(
    () =>
      (safeguards ?? []).map(
        ({ standardName, standardValue, standardLink }) => ({
          name: standardName,
          value: standardLink ? (
            <a
              href={standardLink}
              target="_blank"
              rel="noreferrer"
              className="text-centigrade-green hover:underline font-medium w-fit"
            >
              <Attachment className="inline text-[1.3333em] mr-1" />
              {standardValue}
            </a>
          ) : (
            standardValue
          ),
        })
      ),
    [safeguards]
  )

  // TODO: As a tier0 component, this data should always be available but some projects in dev are incomplete
  if (!attributes.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Socio Environmental Safeguards
      </span>
      <AttributeList maxItems={10}>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
