import clsx from 'clsx'
import { CgSpinnerTwo } from 'react-icons/cg'

export interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {}

export function Spinner({ className, ...rest }: SpinnerProps) {
  return (
    <div
      className={clsx('inline-block', className)}
      role="progressbar"
      {...rest}
    >
      <CgSpinnerTwo className="w-8 h-8 bg-centigrade-carbon text-centigrade-lime rounded-full animate-spin" />
    </div>
  )
}
