import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {AiFillCheckCircle, AiFillBank} from 'react-icons/ai';


import {Alert} from "./Base";
import { apiConfig } from "../env";


export const TokenCard = ({ token }) => {
    const navigate = useNavigate();

    const formatIsoDateString = (isoDateString) => {
        const date = new Date(isoDateString).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short'
          });
        return date;
    };

    const sumAssertionsMinted = (assertions) => {
        let sum = 0;
        if(assertions){
            assertions.forEach(assertion => {
                sum += assertion.amount;
            });
        }
        return sum;
    };

    const sumRetiredUnits = (crus) => {
        let sum = 0;
        if(crus){
            crus.forEach(cru => {
                if(cru.retired){
                    sum += 1;
                }           
            });
        }
        return sum;
    };

    return (<>
    
            <div className="bg-centigrade-creme-200 rounded-lg flex flex-col w-full p-1 mt-1">
                <h5 className="text-centigrade-creme-500">Token Symbol</h5>
                <div className="text-2xl font-mono font-bold div-link text-centigrade-carbon-100 hover:text-yellow-480" onClick={() => navigate(`/tokens/${token.id}`)}>
                    {token?.tokenSymbol}
                </div>
                <div>
                    {/* <div className="text-lg mt-1 font-bold text-centigrade-creme-300">Issuance</div> */}
                    <h5 className="text-centigrade-creme-500">Issuance</h5>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Name</div>
                        <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/transactions/${token?.tx?.tx_hash}`, '_blank')}
                        className="ml-2 justify-start test-left items-baseline link-common">
                            {token?.name}
                        </div>
                    </div>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Vintage</div>
                        <div className="ml-2 justify-start test-left items-baseline">
                            {token?.vintage}
                        </div>
                    </div>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Max Units</div>
                        <div className="ml-2 justify-start test-left items-baseline">
                            {token?.value}
                        </div>
                    </div>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Units Minted</div>
                        <div className="ml-2 justify-start test-left items-baseline">
                            {sumAssertionsMinted(token?.assertions)}
                        </div>
                    </div>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Units Retired</div>
                        <div className="ml-2 justify-start test-left items-baseline">
                            {sumRetiredUnits(token?.carbon_resource_units)}
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    
                    {token?.project?.domain=='carboncure.com' && 
                    <>
                    {/* <div className="text-lg mt-1 font-bold text-centigrade-creme-300">Verification</div> */}
                    <h5 className="text-centigrade-creme-500">Verification</h5>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Method</div>
                        <div className="ml-2 justify-start test-left items-baseline link-common" onClick={()=>window.open('https://verra.org/methodologies/methodology-for-co2-utilization-in-concrete-production/', '_blank')} >
                        VM0043 Methodology for CO2 Utilization in Concrete Production, v1.0
                        </div>
                    </div>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Verification Body</div>
                        <div className="ml-2 justify-start test-left items-baseline" >
                        Self-Assessment
                        </div>
                    </div>
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Verification Date</div>
                        <div className="ml-2 justify-start test-left items-baseline" >
                        9/27/2022
                        </div>
                    </div>              
                    </>}
                    {token?.assertions && token?.assertions.length>0 && 
                    <div className="flex flex-row items-baseline">
                        <div className="text-lg w-48 text-right font-bold items-baseline">Assertion Proof</div>
                        <div className="flex flex-wrap">
                        {token?.assertions && token?.assertions.map((assertion, index) => ( 
                            <div key={index} 
                            onClick={()=>window.open(`${apiConfig().pinataGateway}/${assertion?.ipfsHash}`, '_blank')}
                            className="bg-centigrade-creme-300 text-centigrade-carbon hover:bg-centigrade-sand hover:text-centigrade-carbon rounded-lg m-1 p-1 hover:cursor-pointer underline">
                                {formatIsoDateString(assertion?.created_at)} - {assertion?.amount} tCO2e
                            </div>
                        ))}
                        </div>
                    </div>}
                </div>

                <div className="mt-2">
                    {/* <div className="text-lg mt-1 font-bold text-centigrade-creme-300">Legal</div> */}
                    <h5 className="text-centigrade-creme-500">Legal</h5>
                    <div className="flex flex-row">
                        <ul className="ml-3 text-lg">
                            <li className="flex flex-row items-center">
                                <AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/> Compliance with national, international, local laws
                            </li> 
                            {token?.project?.domain=='carboncure.com' &&<li className="flex flex-row items-center">                 
                                <AiFillBank className="text-2xl text-centigrade-creme-500 mr-1"/> <span onClick={()=>window.open('https://www.carboncure.com/policy/', '_blank')} className="link-common">CarbonCure government and public policy documentation</span>
                            </li>}                  
                        </ul>
                    </div>

                </div>

                <div className="mt-2">
                    {/* <div className="text-lg mt-1 font-bold text-centigrade-creme-300">Social & Environmental Impact</div> */}
                    <h5 className="text-centigrade-creme-500">Social & Environmental Impact</h5>
                    <div className="flex flex-row">
                        <ul className="ml-3 text-lg">
                            <li className="flex flex-row items-center">
                                <AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/>Follows labour rights & working conditions
                            </li>  
                            <li className="flex flex-row items-center">
                                <AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/>Resource efficiency & pollution prevention
                            </li>
                            <li className="flex flex-row items-center">
                                <AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/>Proper and acquisition and no involuntary resettlement
                            </li>
                            <li className="flex flex-row items-center">
                                <AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/>Biodiversity conservation & sustainable Management of living natural resources
                            </li>
                            <li className="flex flex-row items-center">
                                <AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/>Cultural heritage, livelihoods, ancestral knowledge of IP & LCs; No displacement unless agreed upon
                            </li>
                            <li className="flex flex-row items-center">
                                <AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/>Human rights;
                            </li>
                            <li className="flex flex-row items-center"><AiFillCheckCircle className="text-2xl text-centigrade-green mr-1"/>Gender equity
                            </li>
                            {/* {token?.project?.domain=='carboncure.com' &&<li>                 
                                <div className="link-common" onClick={()=>window.open('https://www.carboncure.com/policy/', '_blank')}>Carbon cure government and public policy documentation</div>
                            </li>} */}

                        </ul>
                        
                    </div>
                    

                </div>


                <div>
                    {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                </div>
            </div>    
    
    
    </>);
};

export const TokensList = ({ tokens }) => {


    return (
    <div className="mt-3 flex flex-col w-full">
    {tokens && tokens.length > 0 && 
        <div className="flex flex-col w-full">
            <h4 className="font-bold mt-3">Tokens</h4>
            <Alert text="text-centigrade-creme-500" background="bg-centigrade-creme-200">
            Each token unit represents one tCO2e of net Project greenhouse gas emission reductions and mineralization calculated under the terms of the Protocol titled "Methodology for CO2 Utilization in Concrete Production" as published on the Verra registry.
            </Alert>
        </div>}
        {tokens && tokens.map((token, index) => (   
            <div key={index}>    
            <TokenCard token={token} key={index}/>  
            </div>      
        ))}           
    </div>
    );
};