import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Page } from '../../components/Page'
import { GrapheneRegistryService } from '../../services/GrapheneRegistryService'

const ProjectCards = ({ projects }) => {
  const navigate = useNavigate()

  const truncateTextWithEllipsis = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text
    } else {
      return text.substring(0, maxLength - 3) + '...'
    }
  }

  return (
    <div className="flex flex-col w-full">
      {projects && projects?.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {projects.map((item, index) => (
            <div
              key={index}
              className="flex flex-col rounded-lg p-5 text-centigrade-carbon bg-centigrade-sand hover:bg-centigrade-sky hover:text-centigrade-carbon hover:cursor-pointer"
              onClick={() => navigate(`/projects/${item.id}`)}
            >
              <div className="flex flex-row justify-start">
                <div>
                  <div className="text-lg font-semibold">
                    {item?.project_name}
                  </div>
                  <div className="text-sm font-semibold">
                    {item?.external_id}
                  </div>
                  <div className="text-sm">
                    {truncateTextWithEllipsis(item?.project_description, 300)}
                  </div>
                  <div className="text-sm text-centigrade-navy">
                    {item?.domain}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col w-full">No projects found</div>
      )}
    </div>
  )
}

export const Projects = () => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    GrapheneRegistryService.getProjects().then((res) => {
      setProjects(res.data)
    })
  }, [])

  return (
    <Page>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <h1 className="mt-0 text-2xl">Projects</h1>
        </div>
        <div className="flex flex-row">
          <ProjectCards projects={projects} />
        </div>
      </div>
    </Page>
  )
}
