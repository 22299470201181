import { Routes, Route } from 'react-router-dom';
import { Projects } from './Projects';
import { Project } from './Project';
const ProjectsRoutes: React.FC = () => {
    return (
      <Routes>
        <Route path="/" element={<Projects />} />
        <Route path=":id" element={<Project />} />
      </Routes>
    )
  }

  export default ProjectsRoutes;