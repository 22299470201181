import { useMemo } from 'react'
import { ContactCard } from './components/ContactCard'

export function ProjectEntities({ entities }) {
  // Massage the data into a format that we can pass to ContactCard
  const contacts = useMemo(
    () =>
      (entities ?? []).map((entity) => {
        // There will always be an organization associated with an entity, but that organization
        // may not have a description or address
        const organization = {
          name: entity.organizationName,
          ...(entity.organizationDescription
            ? { description: entity.organizationDescription }
            : undefined),
          ...(entity.address ? { address: entity.address } : undefined),
        }

        // There may or may not be a contact associated with an entity, but if there is, it will
        // always have a name. Other information may be missing.
        const contact = entity.contact
          ? {
              name: entity.contact,
              ...(entity.contactDescription
                ? { description: entity.contactDescription }
                : undefined),
              ...(entity.email ? { email: entity.email } : undefined),
              ...(entity.phone ? { phone: entity.phone } : undefined),
            }
          : undefined

        return {
          role: entity.role,
          organization,
          ...(contact ? { contact } : undefined),
        }
      }),
    [entities]
  )

  // TODO: As a tier0 component, this data should always be available but some projects in dev are incomplete
  if (!contacts.length) {
    return null
  }

  // TODO: For the sake of the demo (and to match the design), I'm splitting the contacts into two groups.
  // The first group will have Proponent, Project/Benefit Owner, and Sponsor, while the second group will
  // have the rest. This assumes BE returns entities in a specific order, which may not be the case.
  // There may also be more than 6 entities, in which case we should divide them more evenly. Lots of
  // assumptions made here...
  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Project Entities
      </span>
      <div className="flex flex-row gap-10">
        <div className="flex-1 flex flex-col gap-4">
          {contacts.slice(0, 3).map((contact, i) => (
            <ContactCard key={i} {...contact} />
          ))}
        </div>
        <div className="flex-1 flex flex-col gap-4">
          {contacts.slice(3).map((contact, i) => (
            <ContactCard key={i} {...contact} />
          ))}
        </div>
      </div>
    </div>
  )
}
