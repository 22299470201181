export { default as ArrowBack } from './svg/arrow_back.svg'
export { default as Attachment } from './svg/attachment.svg'
export { default as Close } from './svg/close.svg'
export { default as CompareArrows } from './svg/compare_arrows.svg'
export { default as Eco } from './svg/eco.svg'
export { default as HeartPlus } from './svg/heart_plus.svg'
export { default as Info } from './svg/info.svg'
export { default as MoreHoriz } from './svg/more_horiz.svg'
export { default as Person } from './svg/person.svg'
export { default as TaskAlt } from './svg/task_alt.svg'
