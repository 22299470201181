import { Header } from './Header'
import { Footer } from './Footer'

export const Page = ({ children }) => {
  return (
    <div className="flex flex-col h-fit min-h-screen justify-between">
      <Header />
      <main className="flex-1 p-8 md:p-16">{children}</main>
      <Footer />
    </div>
  )
}
