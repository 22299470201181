import { Routes, Route } from 'react-router-dom';
import { Reserves } from './Reserves';
import { Reserve } from './Reserve';
const ReservesRoutes: React.FC = () => {
    return (
      <Routes>
        <Route path="/" element={<Reserves />} />
        <Route path=":id" element={<Reserve />} />
      </Routes>
    )
  }

  export default ReservesRoutes;