import { useMemo } from 'react'
import { Attribute } from './components/Attribute'
import { AttributeList } from './components/AttributeList'

const messages = {
  creditingPeriod: {
    name: 'Crediting Period',
    tooltip:
      'The project crediting period is the time period for which GHG emission reductions or removals generated by the project are eligible for issuance as VCUs',
  },
  commitmentPeriod: {
    name: 'Commitment Period',
    tooltip:
      'The period the project proponent commits to take on liabilities associated with ensuring permenance of the emission reduction, which can be longer than the crediting period',
  },
  reversalRisk: {
    name: 'Reversal Risk',
    tooltip:
      'Estimated risk of reversal or release of carbon via natural and social causes prematurely',
  },
  storageType: {
    name: 'Storage Type',
    tooltip: 'Carbon sequestration can be biological or geological',
  },
  durabilityInsuranceType: {
    name: 'Durability Insurance Type',
    tooltip: 'Mitigation measure against a reversal event',
  },
  insurancePolicyOwner: {
    name: 'Insurance Policy Owner',
    tooltip: 'The liable party for compensation once a reversal event happens',
  },
}

export function Durability({ durability }) {
  const attributes = useMemo(
    () =>
      Object.entries(durability ?? {}).map(([key, value]) => {
        // creditingPeriod is an array of a start and end dates
        if (key === 'creditingPeriod' && Array.isArray(value)) {
          value = value.join(' - ')
        }
        const attribute = { name: messages[key].name, value }
        const tooltip = messages[key].tooltip
        if (tooltip) {
          attribute.tooltip = tooltip
        }
        return attribute
      }),
    [durability]
  )

  if (!attributes.length) {
    return null
  }

  return (
    <div>
      <span className="inline-block text-black text-xl font-light mb-4">
        Durability
      </span>
      <AttributeList>
        {attributes.map((attribute, i) => (
          <Attribute key={i} {...attribute} />
        ))}
      </AttributeList>
    </div>
  )
}
