import { useState, useRef, useLayoutEffect } from 'react'
import clsx from 'clsx'
import { Tooltip } from '../../../../components/core'
import { Info } from '../../../../components/icons'

// TODO: Be more strict about the type of value?
// If value is a string, we can check the length directly to set the orientation. However,
// we would need to add an externalLink prop (or something similar) to be able to display value
// as a link. Currently that's possible by passing a React node.

export function Attribute({ name, value, tooltip, className, ...rest }) {
  const valueRef = useRef(null)

  // TODO: Better solution might be to let the consumer specify the orientation
  const [orientation, setOrientation] = useState('horizontal')

  // Check if the attribute value is longer than 50 characters and if so, set the orientation to vertical.
  // We use this method instead of checking value.length since value may be a React node
  useLayoutEffect(() => {
    const valueEl = valueRef.current
    if (valueEl && valueEl.innerText.length > 70) {
      setOrientation('vertical')
    }
  }, [])

  return (
    <li
      className={clsx(
        'flex bg-centigrade-carbon/5 px-4 py-3',
        {
          'flex-col justify-start items-start': orientation === 'vertical',
          'flex-row justify-between items-center': orientation === 'horizontal',
        },
        className
      )}
      {...rest}
    >
      <div
        className={clsx({
          'mr-8': orientation === 'horizontal',
          'mb-1': orientation === 'vertical',
        })}
      >
        <span className={clsx({ "after:content-['_']": !!tooltip })}>
          {name}
        </span>
        {tooltip && (
          <Tooltip content={tooltip}>
            <Info className="inline-block align-middle mb-[3px] text-centigrade-carbon/50" />
          </Tooltip>
        )}
      </div>
      <div
        ref={valueRef}
        className={clsx({
          'text-right max-w-[50%] break-words': orientation === 'horizontal',
        })}
      >
        {value}
      </div>
    </li>
  )
}
