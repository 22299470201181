import { Routes, Route } from 'react-router-dom';
import { Tokens } from './Tokens';
import { Token } from './Token';
const TokensRoutes: React.FC = () => {
    return (
      <Routes>
        <Route path="/" element={<Tokens />} />
        <Route path=":id" element={<Token />} />
      </Routes>
    )
  }

  export default TokensRoutes;