import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Page } from '../../components/Page'

import { GrapheneRegistryService } from '../../services/GrapheneRegistryService'

const TokensList = ({ tokens }) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col w-full">
      {tokens && tokens?.length > 0 ? (
        <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>IWA ID</th>
              <th>Project</th>
              {/* <th>Description</th>
                <th>Domain</th>
                <th>EID</th> */}
            </tr>
          </thead>
          <tbody>
            {tokens &&
              tokens.map((item, index) => (
                <tr key={index} className="mb-1">
                  <td>
                    <span className="text-lg font-mono font-bold text-centigrade-carbon-200">
                      {item?.tokenSymbol}
                    </span>
                  </td>
                  <td>
                    <div
                      className="text-sm font-bold div-link link-common"
                      onClick={() => navigate(`/tokens/${item.id}`)}
                    >
                      {item?.name}
                    </div>
                  </td>
                  <td className="break-words max-w-[300]">{item?.iwa_id}</td>
                  <td className="break-words max-w-[300]">
                    {item?.project && (
                      <div
                        className="text-sm font-bold div-link link-common"
                        onClick={() =>
                          navigate(`/projects/${item?.project.id}`)
                        }
                      >
                        {item?.project?.project_name}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="flex flex-col w-full">No tokens found</div>
      )}
    </div>
  )
}

export const Tokens = () => {
  const [tokens, setTokens] = useState([])

  useEffect(() => {
    GrapheneRegistryService.getTokens().then((res) => {
      setTokens(res.data)
    })
  }, [])

  return (
    <Page>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <h1 className="mt-0 text-2xl">Tokens</h1>
        </div>
        <div className="flex flex-row">
          <TokensList tokens={tokens} />
        </div>
      </div>
    </Page>
  )
}
