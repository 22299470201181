import { useNavigate } from 'react-router-dom'

import { Page } from '../components/Page'

import { BsFillTreeFill } from 'react-icons/bs'
import { GiTwoCoins } from 'react-icons/gi'
import { FaPiggyBank } from 'react-icons/fa'

export const Home = () => {
  const navigate = useNavigate()

  return (
    <Page>
      <div className="font-semibold flex flex-col items-center justify-center md:justify-start items-top">
        <span className="text-4xl">Centigrade Listings</span>

        <h2 className="text-2xl mb-3 text-center">
          Centigrade Listings is a public blockchain record for carbon projects,
          CRU tokens and tCO2 reserve blocks.
        </h2>
        <div className="flex flex-wrap text-xl justify-center">
          <div
            onClick={() => navigate('/projects')}
            className="flex flex-col items-center justify center m-1 w-[256] h-[256] rounded-lg text-centigrade-carbon bg-centigrade-sand p-11 hover:bg-centigrade-sky hover:text-centigrade-carbon hover:cursor-pointer"
          >
            <div>Browse Projects</div>
            <BsFillTreeFill className="m-2 w-[128] h-[128]" />
          </div>
          <div
            onClick={() => navigate('/tokens')}
            className="flex flex-col items-center justify center m-1 w-[256] h-[256] rounded-lg text-centigrade-carbon bg-centigrade-sand p-11 hover:bg-centigrade-sky hover:text-centigrade-carbon hover:cursor-pointer"
          >
            <div>Browse Tokens</div>
            <GiTwoCoins className="m-2 w-[128] h-[128]" />
          </div>
          <div
            onClick={() => navigate('/reserves')}
            className="flex flex-col items-center justify center m-1 w-[256] h-[256] rounded-lg text-centigrade-carbon bg-centigrade-sand hover:bg-centigrade-sky hover:text-centigrade-carbon hover:cursor-pointer p-11"
          >
            <div>Browse Reserves</div>
            <FaPiggyBank className="m-2 w-[128] h-[128]" />
          </div>
        </div>
      </div>
    </Page>
  )
}
